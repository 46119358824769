import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter
} from "@mui/x-data-grid";
import { isMobile } from "react-device-detect";
import styled from "styled-components";

const StyledGridToolbarQuickFilter = styled(GridToolbarQuickFilter)(
  ({ theme }) => ({
    marginLeft: "auto !important",
    width: isMobile ? "100% !important" : "auto",
    padding: "6px !important",
    border: `2px solid ${theme.blueBackground} !important`,
  })
);
export const CustomToolbar = () => {
  return (
    <GridToolbarContainer style={{ justifyContent: "space-between" }}>
      {!isMobile && (
        <div>
          {/* <GridToolbarColumnsButton />
          <GridToolbarDensitySelector /> */}
          <GridToolbarExport />
        </div>
      )}
      <StyledGridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};
