import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import dayjs from "dayjs";
import moment from "moment";
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/pl';
import { useCallback, useEffect, useMemo, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { ContainerDetails } from "../../pages/Containers/ContainerDetails";
import { getCurrentUser } from "../../services/auth-service";
import { IContainer } from "../../services/container-service";
import {
  IDateRange,
  IVehicle,
  searchEvents,
} from "../../services/vehicle-service";
import { UserRoles } from "../../utils";
const CalendarContainer = styled.div`
  .rbc-event {
    background: ${({ theme }) => theme.blueBackground};
  }
  .rbc-today {
    border: ${({ theme }) => `2px solid ${theme.blueBackground}`};
    background: transparent;
  }
  .rbc-off-range {
    color: transparent;
    pointer-events: none;
  }
  .rbc-off-range-bg {
    background: ${({ theme }) => theme.background};
  }
  .rbc-show-more {
    color: ${({ theme }) => theme.textColor};
  }
  .rbc-btn-group {
    button {
      color: ${({ theme }) => theme.textColor};
    }
  }
  .rbc-agenda-time-cell {
    display: none;
  }

`;
export const EVENT_TYPES = {
  TRUCK: "truck",
  VEHICLE: "vehicle",
  CONTAINER: "container",
} as const;
export const EventCalendar = () => {
  const user = getCurrentUser();
  const {t, i18n} = useTranslation();
  const isClient = user?.role === UserRoles.CLIENT;
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [onlyMyItems, setOnlyMyItems] = useState<boolean>(Boolean(searchParams.get("myVehicles")));
  const [events, setEvents] = useState<any>([]);
  const [containerToEdit, setContainerToEdit] = useState<any | undefined>(undefined);
  const initialValues = {
    start: dayjs(firstDay).format("YYYY-MM-DD"),
    end: dayjs(lastDay).format("YYYY-MM-DD"),
  };
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState<IDateRange>(initialValues);
  const localizer = momentLocalizer(moment);
  const onRangeChange = (range: IDateRange) => {
    if(range.start && range.end) {
      setDateRange({
        start: dayjs(range.start).format("YYYY-MM-DD"),
        end: dayjs(range.end).format("YYYY-MM-DD"),
      });
    } else {
      setDateRange({
        // @ts-ignore
        start: dayjs(range[0]).format("YYYY-MM-DD"),
        // @ts-ignore
        end: dayjs(range[range.length - 1]).format("YYYY-MM-DD"),
      });
    }

  };
  const eventDates = (date?: string): IDateRange => {
    return {
      start: dayjs(date).format("YYYY-MM-DD"),
      end: dayjs(date).format("YYYY-MM-DD"),
    };
  };
  const containersData = useMemo(() => {
    return events.containers?.map((obj: IContainer) => ({
      ...obj,
      title: obj.name,
      type: EVENT_TYPES.CONTAINER,
      ...eventDates(obj.expectedDate),
    }));
  }, [events.containers]);
  const vehiclesData = useMemo(() => {
    return events.vehicles?.map((obj: IVehicle) => ({
      ...obj,
      title: obj.description,
      type: EVENT_TYPES.VEHICLE,
      ...eventDates(obj.expectedDate),
    }));
  }, [events.vehicles]);
  const vehicleEvents = [...(vehiclesData || [])];
  const containers = [...(containersData || [])];
  const eventPropGetter = useCallback(
    (event: any) => ({
      ...(event.type === "truck" && {
        style: {
          backgroundColor: "red",
        },
      }),
    }),
    []
  );
  const loadVehicles = useCallback(async () => {
    const { data: eventsData } = await searchEvents(dateRange, onlyMyItems);
    setEvents(eventsData.data);
  }, [dateRange, onlyMyItems]);
  useEffect(() => {
    loadVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, onlyMyItems]);

  useEffect(() => {
    if(isClient) {
      return;
    }
    searchParams.set("onlyMyItems", onlyMyItems ? "1" : "0");
    setSearchParams(searchParams);
  }, [onlyMyItems, searchParams, setSearchParams])
  const onSelectEvent = (event: any) => {
    switch (event.type) {
      case EVENT_TYPES.CONTAINER:
        setContainerToEdit(event)
        return;
      // case EVENT_TYPES.TRUCK:
      //   navigate(`search-truck/${event._id}`);
      //   return;
      default:
        navigate(`search-vehicle/${event.vin}`);
    }
  };
  const getEventIcon = (event: any) => {
    switch (event.type) {
      case EVENT_TYPES.CONTAINER:
        return <DirectionsBoatIcon fontSize="small" />;
      case EVENT_TYPES.TRUCK:
        return <LocalShippingIcon fontSize="small" />;
      default:
        return <DriveEtaIcon fontSize="small" />;
    }
  };
  const EventComponent = (data: any) => {
    const { event } = data;
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {getEventIcon(event)}
        {event.title}
      </div>
    );
  };
  moment.locale("ko", {
    week: {
      dow: 1,
      doy: 1,
    },
  });
  const formats = {
    eventTimeRangeFormat: () => { 
      return "";
    },
  };
  const handleToggleOpen = () => {
    setContainerToEdit(undefined);
  }
  return (
    <CalendarContainer>
            {/* {!isClient && ( */}
                <FormGroup>
                  <FormControlLabel control={<Switch onChange={(event) => {
                    setOnlyMyItems(event.target.checked)
                  }} checked={onlyMyItems} />} label={t("Only my items")} />
                </FormGroup>
            {/* )} */}
      <Calendar
      
      culture={i18n.language.slice(0, 2)}
        components={{ event: EventComponent }}
        eventPropGetter={eventPropGetter}
        onSelectEvent={(event: any) => onSelectEvent(event)}
        popup
        startAccessor={(event) => {
          return new Date(event.start);
        }}
        endAccessor={(event) => {
          return new Date(event.end);
        }}
        dayLayoutAlgorithm="no-overlap"
        formats={formats}
        // showAllEvents
        views={{
          month: true,
          agenda: true,
        }}
        // onDoubleClickEvent={(event) => navigate(`search/${event.vin}`)}
        onRangeChange={(range) => onRangeChange(range as any)}
        localizer={localizer}
        length={7}
        messages={{
          previous: t("Back"),
          next: t("Next"),
          month: t("Month"),
          agenda: t("Week"),
          date: t("Date"),
          time: t("Time"),
          event: t("Event"),
          today: t("Today"),
          noEventsInRange: t("There are no events in this range.")
        }}
        events={
          user?.role === UserRoles.CLIENT
            ? vehicleEvents
            : containers
        }
        style={{ height: "calc(100vh - 150px)" }}
      />

      {containerToEdit && <ContainerDetails handleToggleOpen={handleToggleOpen} containerId={containerToEdit._id} />}
    </CalendarContainer>
  );
};
